import React from "react";
import "./styles.scss";
import { Row, Col } from "react-bootstrap";
import AnimationContainer from "components/animation-container";
import BaffleText from "components/baffle-text";
import ThemeContext from "../../context";
import emailjs, { init } from "emailjs-com";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      error: false,
      show: false,
      sendingMsg: false,
      showSuccessMsg: false,
      showFailureMsg: false,
    };
    this.show = this.show.bind(this);

    init(process.env.EMAILJS_USER_ID || "user_dth6IjM8BvLMmbSKh5Q3z");
  }
  static contextType = ThemeContext;

  show() {
    this.setState({ show: true });
  }

  check(val) {
    if (this.state.error && val === "") {
      return false;
    } else {
      return true;
    }
  }

  async submit() {
    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.message === ""
    ) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false, sendingMsg: true });
      try {
        await emailjs.send("service_8pl3ins", "template_cvQ9SfVD", {
          cname: this.state.name,
          cemail: this.state.email,
          cphone: this.state.phone,
          cmessage: this.state.message,
        });

        this.setState({
          name: "",
          email: "",
          phone: "",
          message: "",
          sendingMsg: false,
          showSuccessMsg: true,
        });

        setTimeout(() => this.setState({ showSuccessMsg: false }), 5000);
      } catch (error) {
        this.setState({ sendingMsg: false, showFailureMsg: true });
        setTimeout(() => this.setState({ showFailureMsg: false }), 5000);
      }
    }
  }

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="contact"
        style={{ height: this.context.height }}
      >
        <Row>
          <Col md={2} className="side">
            <h2>
              <BaffleText
                text="Contact"
                revealDuration={500}
                revealDelay={500}
                parentMethod={this.show}
                callMethodTime={1100}
              />
            </h2>
          </Col>
          <Col md={5} className="form">
            {this.form()}
          </Col>
          <Col md={5} className="map">
            {this.map()}
          </Col>
        </Row>
      </section>
    );
  }

  form() {
    if (this.state.show || this.context.height === "auto") {
      return (
        <AnimationContainer delay={0} animation="fadeInUp fast">
          <div className="form-container">
            <div className="line-text">
              <h4>Get In Touch</h4>
              <AnimationContainer delay={50} animation="fadeInUp fast">
                <div className="form-group">
                  <input
                    type="text"
                    className={`name ${
                      this.check(this.state.name) ? "" : "error"
                    }`}
                    placeholder="Name"
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
              </AnimationContainer>
              <AnimationContainer delay={100} animation="fadeInUp fast">
                <div className="form-group">
                  <input
                    type="text"
                    className={`email ${
                      this.check(this.state.email) ? "" : "error"
                    }`}
                    placeholder="Email"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>
              </AnimationContainer>
              <AnimationContainer delay={150} animation="fadeInUp fast">
                <div className="form-group">
                  <input
                    type="text"
                    className="phone"
                    placeholder="Phone"
                    value={this.state.phone}
                    onChange={(e) => this.setState({ phone: e.target.value })}
                  />
                </div>
              </AnimationContainer>
              <AnimationContainer delay={200} animation="fadeInUp fast">
                <div className="form-group">
                  <textarea
                    className={`message ${
                      this.check(this.state.message) ? "" : "error"
                    }`}
                    placeholder="Message"
                    value={this.state.message}
                    onChange={(e) => this.setState({ message: e.target.value })}
                  ></textarea>
                </div>
              </AnimationContainer>
              <AnimationContainer delay={250} animation="fadeInUp fast">
                <div className="submit">
                  <button
                    className={`hover-button ${
                      this.state.error ? "error" : ""
                    }`}
                    disabled={this.state.sendingMsg}
                    onClick={() => this.submit()}
                  >
                    <span>
                      {!this.state.sendingMsg ? "Send Message" : "Sending..."}
                    </span>
                  </button>
                </div>
                <div className="msg">
                  <p>
                    {this.state.showSuccessMsg
                      ? "Successfully sent your message to Shrey!"
                      : this.state.showFailureMsg
                      ? "Unfortunately your messeage was not sent, please try later."
                      : ""}
                  </p>
                </div>
              </AnimationContainer>
            </div>
          </div>
        </AnimationContainer>
      );
    }
  }

  map() {
    if (this.state.show || this.context.height === "auto") {
      return (
        <AnimationContainer
          delay={1000}
          animation="fadeIn fast"
          height={this.context.height}
        >
          <iframe
            title="map"
            width="100%"
            height="100%"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2644.3933171031968!2d-123.32663878421991!3d48.48734947925281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548f7170d8f65101%3A0x28561e0a64b278bd!2s4416%20Torquay%20Dr%2C%20Victoria%2C%20BC%20V8N%203L4%2C%20Canada!5e0!3m2!1sen!2sin!4v1624044889834!5m2!1sen!2sin"
            allowfullscreen
            loading="lazy"
          />
        </AnimationContainer>
      );
    }
  }
}

export default Contact;
