import React from "react";
import Particles from "react-particles-js";
import Progress from "components/progress";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import ThemeContext from "../../context";
import "./styles.scss";

class Hero extends React.Component {
  static contextType = ThemeContext;

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="about"
        style={{ height: this.context.height }}
      >
        {this.particles()}
        <Row>
          <Col md={6} className="content">
            <div className="content-text">
              <div className="line-text">
                <h4>About Me</h4>
              </div>
              <h3>An Aspiring Developer</h3>
              <div className="separator" />
              <p>
                I'm a student based in Victoria, BC developing solutions to
                mordern day problems. I am pursuing a BSc. of Computer Science
                at University of Victoria. I am passionate about building
                technology that has the potential to make a significant
                difference in people's lives, whether it be in healthcare,
                education or simply improving their day-to-day activities. I
                strive to create reliable, user-centered and scalable software
                products and believe that this is only achievable when working
                in a highly driven team that truly cares about architecting and
                delivering robust software.
              </p>
              <div className="social social_icons">
                <FontAwesomeIcon
                  icon={faGithub}
                  className="social_icon"
                  onClick={() =>
                    window.open("https://www.github.com/shreypdev")
                  }
                />
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="social_icon"
                  onClick={() =>
                    window.open("https://www.linkedin.com/in/shrey-p-a61a6b95/")
                  }
                />
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="social_icon"
                  onClick={() =>
                    window.open("https://www.instagram.com/samyang_boi/")
                  }
                />
              </div>
            </div>
          </Col>
          <Col md={6} className="skills">
            <div className="line-text">
              <h4>My Skills</h4>
            </div>
            <div className="skills-container">
              <Progress name="React" value={90} delay={1100} />
              <Progress name="Angular" value={60} delay={1100} />
              <Progress name="NestJS" value={80} delay={1100} />
              <Progress name="C and C++" value={70} delay={1100} />
              <Progress name="Java" value={90} delay={1100} />
              <Progress name="Qiskit and Q#" value={60} delay={1100} />
              <Progress name="MySQL and MongoDB" value={80} delay={1100} />
              <Progress name="Firebase" value={95} delay={1100} />
            </div>
          </Col>
        </Row>
      </section>
    );
  }

  particles() {
    return (
      <Particles
        className="particles"
        params={{
          particles: {
            number: {
              value: 50,
              density: {
                enable: false,
                value_area: 5000,
              },
            },
            line_linked: {
              enable: true,
              opacity: 0.5,
            },
            size: {
              value: 1,
            },
          },
          retina_detect: true,
        }}
      />
    );
  }
}

export default Hero;
