import React from "react";
import "./styles.scss";
import { Row, Col } from "react-bootstrap";
import BaffleText from "components/baffle-text";
import AnimationContainer from "components/animation-container";
import ThemeContext from "../../context";

class WorkExperience extends React.Component {
  constructor() {
    super();
    this.state = {
      showPortfolio: false,
    };
    this.showPortfolio = this.showPortfolio.bind(this);
  }
  static contextType = ThemeContext;

  showPortfolio() {
    this.setState({ showPortfolio: true });
  }

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="portfolio"
        style={{ height: this.context.height }}
      >
        <Row>
          <Col md={2} className="side">
            <h2>
              <BaffleText
                text="Work Experience"
                revealDuration={500}
                revealDelay={500}
                parentMethod={this.showPortfolio}
                callMethodTime={1100}
              />
            </h2>
          </Col>
          <Col md={10} className="works">
            <AnimationContainer delay={200} animation="fadeInDown slow">
              <div className="work">
                <h2>
                  Full-Stack Software Developer <h5>May 2020 – Dec 2020</h5>
                </h2>
                <h4>Shift @ Redbrick</h4>
                <ul>
                  <li>
                    Responsible for implementing new features, components and
                    resolving production issues in Shift.
                  </li>
                  <li>
                    Implemented Browser Tabs Hibernation Manager for power
                    efficiency of machine, Password Manager to store user’s
                    passwords securely with End-to-End Encryption. Helped team
                    in changing overall UI/UX of Shift.
                  </li>
                  <li>
                    <b>Worked with</b> → ReactJS, Typescript, AWS
                  </li>
                </ul>
              </div>
              <hr />
            </AnimationContainer>
            <AnimationContainer delay={400} animation="fadeInRight slow">
              <div className="work">
                <h2>
                  Software Developer & Design Technician{" "}
                  <h5>Jan 2019 – Aug 2019</h5>
                </h2>
                <h4>Schneider Electric</h4>
                <ul>
                  <li>
                    Development and maintenance of a wide variety of web apps
                    and services across a spectrum of server and client
                    technologies.
                  </li>
                  <li>
                    Assist in the continuous improvement of product quality and
                    contribute to the product and planning decisions.
                  </li>
                  <li>
                    Working with a continues software delivery build pipeline
                    and virtual machine infrastructure for testing and debugging
                    while developing software.
                  </li>
                  <li>
                    A member of a software team constantly striving for
                    improvement in readily changing commercial environment.
                  </li>
                  <li>
                    <b>Worked with</b> → HTML, CSS, JavaScript, jQuery,
                    Typescript, C#, C++, .NET, ASP, SQL, XAML, XML
                  </li>
                </ul>
              </div>
              <hr />
            </AnimationContainer>
            <AnimationContainer delay={600} animation="fadeInUp slow">
              <div className="work">
                <h2>
                  Full-Stack Web & System Developer{" "}
                  <h5>Sept 2018 – Dec 2018</h5>
                </h2>
                <h4>Superior Tray Systems</h4>
                <ul>
                  <li>
                    Developed web portal through which clients can get a
                    quotation, which <b>saved about 80%</b> of the electrical
                    engineer’s time when compared to the time they were spending
                    on getting the quotations before developing this software.
                  </li>
                  <li>
                    Quotation includes analyzing the best possible electric
                    system configuration, thereafter calculation of material
                    required and finally calculating the final cost of making
                    cable tray, cable bus, high bus.
                  </li>
                  <li>
                    <b>Worked with</b> → HTML, CSS, JavaScript, jQuery, Angular,
                    Firebase, C++, VBA, Google Cloud
                  </li>
                </ul>
              </div>
            </AnimationContainer>
          </Col>
        </Row>
      </section>
    );
  }
}

export default WorkExperience;
